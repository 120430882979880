// extracted by mini-css-extract-plugin
export var afterCaption = "maine_digest_2022_04-module--afterCaption--f0081";
export var colWrapper = "maine_digest_2022_04-module--colWrapper--e3f0d";
export var columnWrapper = "maine_digest_2022_04-module--columnWrapper--1afff";
export var dateline = "maine_digest_2022_04-module--dateline--6a4fb";
export var heading = "maine_digest_2022_04-module--heading--dbd12";
export var headingLogo = "maine_digest_2022_04-module--headingLogo--1ea1f";
export var headingWrapper = "maine_digest_2022_04-module--headingWrapper--33612";
export var heroImage = "maine_digest_2022_04-module--heroImage--51d02";
export var instance = "maine_digest_2022_04-module--instance--2960c";
export var latestnewsarticleheadline = "maine_digest_2022_04-module--latestnewsarticleheadline--415be";
export var leftCol = "maine_digest_2022_04-module--leftCol--daa73";
export var library = "maine_digest_2022_04-module--library--3c149";
export var librarypaper = "maine_digest_2022_04-module--librarypaper--719af";
export var maineDigest = "maine_digest_2022_04-module--maineDigest--df3d8";
export var photoCaption = "maine_digest_2022_04-module--photoCaption--e6ae4";
export var rightCol = "maine_digest_2022_04-module--rightCol--2f12f";
export var subheading = "maine_digest_2022_04-module--subheading--32237";
export var textWrapper = "maine_digest_2022_04-module--textWrapper--54949";
export var whatsNew = "maine_digest_2022_04-module--whatsNew--abce1";